<template>
  <v-container>
    <v-toolbar rounded>
      <v-toolbar-title>Clientes</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="newForm">
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="10"
      item-key="code"
      sort-by="name"
      mobile-breakpoint="10"
      class="table-cursor  elevation-1 mt-3"
      :search="search"
      :loading="loading_status"
      loader-height="10"
      loading-text="Cargando ..."
      @click:row="handleClick"
    >
      <template v-slot:top>
        <v-text-field v-model="search" label="Buscar" class="mx-4" />
      </template>
      <!-- <template v-slot:[`item.picture`]="{ item }">
        <v-list-item-avatar>
          <v-img :src="getImagePhoto(item.account, item.picture)"></v-img>
        </v-list-item-avatar>
      </template> -->
      <template v-slot:[`item.name`]="{ item }">
        {{ item.name }} <br />
        <small>
          {{ item.email }}
        </small>
      </template>
    </v-data-table>

    <PartyForm
      :table="table"
      :item="item"
      :dialog="dialog"
      :newItem="newItem"
      @close="dialog = false"
      @refresh="refresh()"
    />

    <v-dialog v-model="loading_status" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Cargando ...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { webserver } from "../services/webserver.js";
import createItem from "../utils/createParty.js";
import PartyForm from "../components/PartyForm.vue";
export default {
  components: { PartyForm },
  mounted() {
    this.get_items();
  },
  data() {
    return {
      table: "clients",
      dialog: false,
      newItem: false,
      search: "",
      loading_status: false,
      errors: [],
      items: [],
      item: createItem(),
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "name",
          dataType: "text",
        },

        {
          text: "",
          align: "end",
          sortable: true,
          value: "mobile",
        },
      ],
    };
  },
  methods: {
    handleClick(data) {
      this.item = { ...data };
      this.newItem = false;
      this.dialog = true;
    },
    updateLocation(e) {
      this.store.location = JSON.stringify(e);
    },
    get_items() {
      var qry = {
        account: this.$store.state.profile.account,
        table: this.table,
        filters: [],
      };
      this.loading_status = true;
      webserver("get_table", qry, (data) => {
        this.loading_status = false;
        this.items = data;
        if (data.length > 0) {
          this.items = data;
        } else {
          this.newForm();
        }
      });
    },
    refresh() {
      this.dialog = false;
      this.get_items();
    },
    getImagePhoto: function (account, src) {
      var url = "https://sw67.ca/photos/" + account + "/" + src;
      if (this.compressed_image) url = this.compressed_image;
      return url;
    },
    newForm() {
      this.item = createItem();
      this.item.account = this.$store.state.profile.account;
      this.valid = false;
      this.newItem = true;
      this.dialog = true;
    },
    call_contact(data) {
      window.location.href = "tel://" + data;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
